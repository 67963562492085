import classNames from 'classnames';
import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';

import CardHeader from './CardHeader';
import CardBody from './CardBody';
import CardFooter from './CardFooter';

import styles from './card.css';


const propTypes = {

    /**
    * The card's styling variant
    *
    * @type {('primary'|'secondary'|'grouping'|'selectable'|'inverse')}
    *
    */
    variant: PropTypes.string,

    border: PropTypes.string,

    className: PropTypes.string,

    id: PropTypes.string,

    isLoading: PropTypes.bool,

    removePadding: PropTypes.bool,
};

const defaultProps = {
    variant: 'primary',
    isLoading: false,
    removePadding: false
};

const Card =
    (
        {
            variant,
            border,
            className,
            id,
            isLoading,
            removePadding,
            cardSelectable,
            ...props
        }
    ) => {

        const prefix = 'card';

        const classes = classNames(
            prefix,
            variant && ('' + prefix + '-' + variant),
            border && ('' + prefix + '-' + border),
            removePadding && ('' + prefix + '-remove-padding'),
            isLoading && "cardLoading",
            className,
            { "selected": cardSelectable && cardSelectable.selected  }
        );

        const Component = 'div';

        return (
            <Component
                className={classes}
                id={id}
                {...props} />
        );
    };

Card.displayName = 'Card';
Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Footer = CardFooter;

export default Card;


// ====================================================
// Deprecated
// ====================================================

function CardInternal(props) {
    return (
        <div className={props.classes} id={props.id} onClick={props.onClick}>
            {props.children}
        </div>
    )
}


export function CardPrimary(props) {
	const classes = classNames(
		"card",
		"card-primary",
		props.className,
		props.isLoading && "cardLoading"
	);
    return (
        <CardInternal classes={classes} id={props.id} onClick={props.onClick}>
            {props.children}
        </CardInternal >
    )
}


export function CardSecondary(props) {
    let additionalClasses = props.className ? " " + props.className : "";
    return (
        <CardInternal classes={"card card-secondary" + additionalClasses} id={props.id} onClick={props.onClick}>
            {props.children}
        </CardInternal >
    )
}

export function CardInverse(props) {
    let additionalClasses = props.className ? " " + props.className : "";
    return (
        <CardInternal classes={"card card-inverse" + additionalClasses} id={props.id} onClick={props.onClick}>
            {props.children}
        </CardInternal >
    )
}


export function CardGrouping(props) {
    let aditionalClasses = props.className ? " " + props.className : "";
    return (
        <CardInternal classes={"card card-grouping" + aditionalClasses} id={props.id} onClick={props.onClick}>
            {props.children}
        </CardInternal >
    )
}


export function CardSelectable(props) {
    let cardSelectable = props.cardSelectable
    let isSelected = false

    if (cardSelectable) {
        isSelected = cardSelectable.selected
    }

    let aditionalClasses = props.className ? " " + props.className : "";

    if (isSelected) {
        return (
            <CardInternal classes={"card card-selectable selected" + aditionalClasses} onClick={props.onClick}>
                {props.children}
            </CardInternal >)
    } else {
        return (
            <CardInternal classes={"card card-selectable" + aditionalClasses} onClick={props.onClick}>
                {props.children}
            </CardInternal >
        )
    }
}

