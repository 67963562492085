import React, { Component } from 'react'

import {CardPrimary} from "Components/Card/Card"

import "./tooltip.css"

function Tooltip(props) {
    if (props.scheduleUpdate) {
        props.scheduleUpdate()
    }
    return (
        <div className={"tooltip"} >
            <CardPrimary>
                {props.children}
            </CardPrimary>
        </div>
    )
}

export default Tooltip