import React from 'react'
import "./Form.css"

function SubmitForm(submitEvent, e) {
    e.preventDefault();

    const fields = e.target.elements;

    if (fields && fields.length !== 0) {
        for (let i = 0; i < fields.length; i++) {
            fields[i].focus();
            fields[i].blur();
        }
    }

    if (submitEvent) {
        submitEvent();
    }
}

export default function Form(props) {
    return (
        <form id={props.id} className={props.className} onSubmit={(e) => SubmitForm(props.onSubmit, e)} >
            {props.children}
        </form>
    )
}