import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import "./divider.css"

const DividerLeft = React.forwardRef(
    (
        {
            ...props
        },
        ref
    ) => {
        return (
            <div className="leftWrapper">
                {props.children}
            </div>
        );
    }
)

const DividerRight = React.forwardRef(
    (
        {
            ...props
        },
        ref
    ) => {
        return (
            <div className="rightWrapper">
                <div className="rightMobileSupport">
                    {props.children}
                </div>
            </div>
        );
    }
)

const propTypes = {
    /**
     * @default 'divider'
     */
    prefix: PropTypes.string,

    /**
     * One or more divider variant combinations
     *
     * buttons may be one of a variety of visual variants such as:
     *
     * `'horizontal', 'vertical',`
     */
    variant: PropTypes.string,

    /**
     * Specifies a divider with the 'or' styling.
     */
    or: PropTypes.bool,

    /**
    * When this prop is set, it creates a Divider with a Divider.Left inside
    * passing the children directly to it
    */
    left: PropTypes.bool,

    /**
    * When this prop is set, it creates a Divider with a Divider.Right inside
    * passing the children directly to it
    */
    right: PropTypes.bool,
};

const defaultProps = {
    prefix: 'divider',
    variant: 'horizontal',
    or: false,
    left: false,
    right: false
};

const Divider = React.forwardRef(
    (
        {
            prefix,
            variant,
            or,
            left,
            right,
            className,
            ...props
        },
        ref,
    ) => {

        const classes = classNames(
            className,
            prefix,
            `${prefix}-${variant}`,
            or && 'or',
        );

        const Component = 'div';
        if (ref) props.ref = ref;

        return (
            <Component {...props} className={classes} >
                {props.children}
            </Component>
        );
    }
);

Divider.displayName = 'Divider';
Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

Divider.Left = DividerLeft;
Divider.Right = DividerRight;


export default Divider;
