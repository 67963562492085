import classNames from 'classnames';
import React from 'react'
import PropTypes from 'prop-types';

import CardAction from './CardAction';

const propTypes = {
    className: PropTypes.string
};

const defaultProps = {

};

const CardHeader = (
    {
        className,
        ...props
    }) => {

    const prefix = 'card-header';

    const classes = classNames(
        prefix,
        className
    );

    const Component = 'div';

    return (
        <Component
            className={classes}
            {...props} />
    );
}

CardHeader.displayName = 'CardHeader';
CardHeader.propTypes = propTypes;
CardHeader.defaultProps = defaultProps;

CardHeader.Action = CardAction;

export default CardHeader;