import React from 'react'

import './Textarea.css';

function Textarea(props) {
    return (
        <div className={`textAreaContainer  ${props.errorMessages && props.errorMessages.length ? "hasError" : ""}`}>
            <textarea rows="2" maxLength="165" className={props.className} placeholder={props.placeholder} onChange={props.onChange} onBlur={props.onBlur} defaultValue={props.defaultValue} aria-label={props.ariaLabel}></textarea>
            {props.errorMessages ? props.errorMessages.map((message, index) => <div key={index} className="errorMessage">{message}</div>) : false}
        </div>
    )
}

export default Textarea