import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import Tooltip from 'Components/Tooltip/Tooltip'
import {sendGoogleAnalyticsEvent} from 'Actions/googleAnalyticsAction';
import './infoIcon.css';
import PropTypes from 'prop-types';

import {Manager, Popper, Reference} from 'react-popper'

class InfoIcon extends Component {
    constructor(props) {
        super(props);
        this.handleInfoIconClick = this.handleInfoIconClick.bind(this);

        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.state = { isInfoIconActive: false };
    }

    handleInfoIconClick() {
        if (!this.state.isInfoIconActive) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState({ isInfoIconActive: !this.state.isInfoIconActive })

    }


    handleOutsideClick(event, e) {
        if (!ReactDOM.findDOMNode(this).contains(event.target)) {
            this.handleInfoIconClick();
        }
        return;

    }

    render() {
        let key = Date.now() + Math.random()
        let infoIconTooltip;
        if (this.state.isInfoIconActive && !navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
	        this.props.sendGoogleAnalyticsEvent(this.props.gaCategory, 'Clicked ' + this.props.name + ' tooltip')
        }
        let popper =
            (
                <span onClick={this.handleInfoIconClick.bind({ key })} >
 
                        <Manager>
                            <Reference>
                                {({ ref }) => (
                                    <div className="iconTooltip" ref={ref}>
                                        {this.props.infoIcon.svg}
                                    </div>

                                )}
                            </Reference>
                            <Popper placement="top">
                                {({ ref, style, placement, arrowProps, scheduleUpdate }) => (
                                    <div ref={ref} className="popper" style={style} data-placement={placement}>
                                        {this.state.isInfoIconActive ?
                                            <Tooltip scheduleUpdate={scheduleUpdate}>
                                                {this.props.infoIcon.text}
                                            </Tooltip> : false}
                                        
                                    </div>
                                )}
                            </Popper>
                        </Manager>
      
                </span>
            )
        return (
            <>

                {popper}
            </>
        )
    }
}

const mapDispatchToProps = {
	sendGoogleAnalyticsEvent
}

InfoIcon.propTypes = {
	gaCategory: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
}

export default connect(null,mapDispatchToProps)(InfoIcon)

