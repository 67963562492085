import classNames from 'classnames';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import "./slideOut.css"

const propTypes = {
    /**
     * When `true` The slide out will show itself.
     */
    show: PropTypes.bool,
};

const defaultProps = {
    show: false
};

const SlideOut = (
    {
        className,
        header,
        content,
        show
    }
) => {

    let setBodyPosition = (position) => {
        let body = document.getElementById('webpageBody');
        if (body) {
            body.style.position = position
        }
    }

    if(show){
        setBodyPosition("fixed")
    }

    const prefix = 'slide-out';

    const classes = classNames(
        prefix,
        className
    );

    const Component = 'div';

    if (!show) {
        setBodyPosition("static")
        return false;
    }

    return (
        <Component className={classes}>
            <div className={'slideOutContainer'}>
                <div className={'slideOutHeader'}>
                    {header}
                </div>
                <div className={'slideOutContent'}>
                    {content}
                </div>
            </div>
        </Component>
    );
}

SlideOut.displayName = 'SlideOut';
SlideOut.propTypes = propTypes;
SlideOut.defaultProps = defaultProps;

export default SlideOut