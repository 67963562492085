import React from 'react'
import { connect } from 'react-redux';
import './dropdown.css';

const Options = (props) => {
    let optionArray = props.options ? props.options.map((o) => <option key={o.key} value={o.value}>{o.text} </option>) : "";
    return (
        <>
            <option value="0">{props.emptyOptionString}</option>
            {optionArray}
        </>
    );
}

function Dropdown(props) {
    let key = props.className ? props.className + "_" + props.dropdown.defaultValue : null

    return (
        <label className={`dropdownContainer ${props.className ? props.className : ""} ${props.errorMessages && props.errorMessages.length ? "hasError" : ""}`} htmlFor={props.dropdown.name}>
            <div className={props.dropdown.isRequired ? "labelHeader required" : "labelHeader optional"}>
                {props.dropdown.header}
            </div>
            <select
                className="select"
                key={key}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                name={props.dropdown.name}
            >
                <Options options={props.dropdown.options} emptyOptionString={props.dropdown.emptyOptionString} />
            </select>
            {props.errorMessages ? props.errorMessages.map((message, index) => <div key={index} className="errorMessage">{message}</div>) : false}

        </label>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        value: state.transaction[ownProps.formName][ownProps.propName]
    }
}

export default connect(mapStateToProps, null)(Dropdown)