import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import MediaImage from './MediaImage';


const propTypes = {

};

const defaultProps = {
};

const Media = React.forwardRef(
    (
        {
            className,
            ...props
        },
        ref,
    ) => {
        const prefix = 'media';

        const classes = classNames(
            prefix,
            className
        )

        const Component = 'media';

        return (
            <Component
                className={classes}
                ref={ref}
                {...props}
            />
        );
    }
);

Media.displayName = 'Media';
Media.propTypes = propTypes;
Media.defaultProps = defaultProps;

Media.Image = MediaImage;

export default Media;
