import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import FormContext from './FormContext';

import './formLabel.css'

const propTypes = {
    /**
     * @default 'form-label'
     */
    prefix: PropTypes.string,

    /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
    htmlFor: PropTypes.string,

    /**
    * Indicates if the related Form Control element is required or not
    */
    required: PropTypes.bool,

    /**
    * Hides the required (*) and the optional (Optional) after pseudo elements
    */
    hideAddOn: PropTypes.bool,

    /**
     * The FormLabel `ref` will be forwarded to the underlying element.
     * Unless the FormLabel is rendered `as` a composite component,
     * it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,
};

const defaultProps = {
    required: false,
    hideAddOn: false
};

const FormLabel = React.forwardRef(
    (
        {
            prefix,
            className,
            htmlFor,
            required,
            hideAddOn,
            ...props
        },
        ref
    ) => {
        const { controlId } = useContext(FormContext);

        prefix = 'form-label';

        const classes = classNames(
            prefix,
            required && !hideAddOn ? prefix + '-required' : '',
            !required && !hideAddOn ? prefix + '-optional' : '',
            className,
        );

        htmlFor = htmlFor || controlId;

        const Component = 'label';

        return (
            <Component
                ref={ref}
                className={classes}
                htmlFor={htmlFor}
                {...props}
            />
        );
    },
);

FormLabel.displayName = 'FormLabel';
FormLabel.propTypes = propTypes;
FormLabel.defaultProps = defaultProps;

export default FormLabel;