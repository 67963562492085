import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import "./button.css"

const propTypes = {
    /**
     * One or more button variant combinations
     *
     * buttons may be one of a variety of visual variants such as:
     *
     * `'primary', 'secondary', 'confirmation', 'link'`
     *
     */
    variant: PropTypes.string,

    /**
     * Size of the button
     *
     * @type {('small'|'large')}
     *
     */
    size: PropTypes.string,

    /**
     * Disables the Button, preventing mouse events,
     * even if the underlying component is an `<a>` element
     */
    disabled: PropTypes.bool,

    inline: PropTypes.bool,

    /** Providing a `href` will render an `<a>` element, _styled_ as a button. */
    href: PropTypes.string,

    onClick: PropTypes.func,

    /**
     * Defines HTML button type attribute.
     *
     * @default 'button'
     */
    type: PropTypes.oneOf(['button', 'reset', 'submit', null]),

};

const defaultProps = {
    disabled: false,
    type: 'button',
    onClick: () => { },
    inline: false
};

const Button = React.forwardRef(
    (
        {
            variant,
            size,
            className,
            type,
            onClick,
            href,
            disabled,
            ariaLabel,
            value,
            inline,
            bold,
            ...props
        },
        ref,
    ) => {
        const prefix = 'btnReact';

        if (href && !variant) {
            variant = 'link';
        } else if (!variant) {
            variant = 'primary';
        }

        const classes = classNames(
            className,
            prefix,
            `${prefix}-${variant}`,
            size && `${prefix}-${size}`,
            inline && 'inlineBtn',
            bold && 'bold'
        );

        if (variant === 'link' || variant === 'link-alt' || href) {
            return (
                <a
                    {...props}
                    href={href ? href : "javascript:void(0);"}
                    className={classes}
                    aria-label={ariaLabel}
                    onClick={onClick}
                />
            );
        }

        const Component = type === 'submit' ? 'input' : 'button';
        if (ref) {
            props.ref = ref
        }

        return <Component {...props} type={type} className={classes} onClick={onClick} disabled={disabled} aria-label={ariaLabel} value={value}/>
    }
)

Button.displayName = 'Button'
Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button;