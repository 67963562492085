import React, { Component } from 'react'
import { connect } from 'react-redux';
import classNames from 'classnames';
import './textbox.css';
import { WarningSvgIcon } from 'Components/SvgIcon/SvgIcon'


class Textbox extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let props = this.props;

        const classes = classNames(
            'textboxContainer',
            props.className,
            props.errorMessages && props.errorMessages.length ? "hasError" : "",
            props.warningMessages && props.warningMessages.length ? "hasWarning" : ""
        );

        return (
            <div className={classes}>
                <TextboxContent content={props} />
            </div>
        )
    }
} 

class TextboxDropdownInternal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isTextBoxFocused: false
        }

        this.handleOnBlur = this.handleOnBlur.bind(this)
        this.handleOnFocus = this.handleOnFocus.bind(this)
    }

    handleOnBlur(e) {
        this.setState({ isTextBoxFocused: false })
    }

    handleOnFocus(e) {
        this.setState({ isTextBoxFocused: true })
    }


    render() {
        let props = this.props
        let hasErrorState = props.errorMessages && props.errorMessages.length
        let hasWarningState = props.warningMessages && props.warningMessages.length
        let dropdownItems = []

        if (props.dropdownItems && this.state.isTextBoxFocused) {
            props.dropdownItems.forEach((item, index) => {
                dropdownItems.push(<div key={index} data-item={item.data} className='dropdownItemContainer' onMouseDown={props.dropdownItemOnClick}>{item.content}</div>)
            })
        }

        props.textbox["className"] = dropdownItems.length > 0 ? "hasDropdownItems" : ""

        const dropdownClasses = classNames(
            "dropdownContainer",
            hasErrorState ? "dropdownError" : ""
        );

        let dropdownList = (
            <div className={dropdownClasses}>
                <div className='dropdownList'>
                    {dropdownItems}
                </div>
            </div>
        )

        const classes = classNames(
            "textboxContainer",
            "textboxDropdown",
            props.className ? props.className : "",
            hasErrorState ? "hasError" : "",
            hasWarningState ? "hasWarning" : ""
        );

        return (
            <div onBlur={this.handleOnBlur} className={classes}>
                <TextboxContent content={props} dropdownFocusHandler={this.handleOnFocus} />
                {dropdownItems.length > 0 ? dropdownList : null}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    if (ownProps.useReactState) {
        return {
            value: ownProps.value
        }
    }
    return {
        value: state.transaction[ownProps.formName][ownProps.propName]
    }
}
export default connect(mapStateToProps, null)(Textbox)
export const TextboxDropdown = connect(mapStateToProps, null)(TextboxDropdownInternal)

function TextboxContent(props) {
    const { content, dropdownFocusHandler } = props

    const labelHeaderClasses = classNames(
        "labelHeader",
        content.textbox.isRequired ? "required" : "optional"
    );

    const inputClasses = classNames(
        "input",
        content.textbox.isValid === false ? "input-validation-error" : "",
        content.textbox.hideSpinner ? " hideSpinner" : "",
        content.textbox.className
    );

    const maxLength = content.textbox.maxLength > 0 ? `${content.textbox.maxLength}` : undefined
    const max = content.textbox.maxLength && content.type === 'number' ? `${generateMaxForNumberInput(content.textbox.maxLength)}` : undefined
    const min = content.textbox.maxLength && content.type === 'number' ? `${generateMinForNumberInput(content.textbox.maxLength)}` : undefined

    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    return (
        <>
            {content.textbox.infoIcon ? content.textbox.infoIcon : null}
            <label>
                <div className={labelHeaderClasses}>{content.textbox.header}</div>
                <input type={content.type === 'internationalTel' ? 'tel' : content.type }
                    id={content.id}
                    className={inputClasses}
                    placeholder={content.textbox.placeholder}
                    onChange={(e) => onChangeWrapper(content.onChange, e)}
                    onBlur={content.onBlur}
                    onFocus={(e) => {
                        if (dropdownFocusHandler) {
                            dropdownFocusHandler(e)
                        }
                        if (content.onFocus) {
                            content.onFocus(e)
                        }
                    }}
                    onKeyUp={content.onKeyUp}
                    onKeyDown={(e) => onKeyDownWrapper(e, content.onKeyDown, content.type, content.id)}
                    value={content.value || ""}
                    autoComplete={content.autocomplete === 'tel' && content.type !== 'internationalTel' && isFirefox ? 'tel-national' : content.autocomplete}
                    name={content.name}
                    maxLength={maxLength}
                    max={max}
                    min={min}
                />
            </label >
            {content.errorMessages ? content.errorMessages.map((message, index) => <div key={index} className="errorMessage">{message}</div>) : false}
            {content.warningMessages ? content.warningMessages.map((message, index) => <div key={index} className="warningMessage"><WarningSvgIcon/>{message}</div> ) : false}
        </>
    )
}

// You cannot specify a maxLength for number inputs
// So we need to generate the max number that the input could be, give the amount of characters.
function generateMaxForNumberInput(n) {
    if (!n) {
        return "0";
    }

    let number = ""
    for (let i = 0; i < n; i++) {
        number += "9"
    }
    return number;
};

// You cannot specify a minLength for number inputs
// So we need to generate the min number that the input could be, give the amount of characters.
function generateMinForNumberInput(n) {
    if (!n) {
        return "0";
    }

    let number = "-";
    for (let i = 0; i < n - 1; i++) {
        number += "9";
    }
    return number;
}

function onChangeWrapper(onChangeEvent, e) {
    

    restrictNumberFieldMaxLength(e);
    restrictTelFieldValidCharacters(e);

    if (onChangeEvent) {
        onChangeEvent(e);
    }
}

function restrictNumberFieldMaxLength(e) {
    if (e.target.type !== 'number')
        return;

    if (!e.target.maxLength)
        return;

    if (!e.target.value)
        return;

    if (e.target.value.length > e.target.maxLength)
        e.target.value = e.target.value.slice(0, e.target.maxLength);
}

function restrictTelFieldValidCharacters(e) {
    if (e.target.type !== 'tel')
        return;
    if (!e.target.value)
        return;
    const dirtyInput = e.target.value;
    const cleanedInput = ('' + dirtyInput).replace(/[^\d\s\(\)\-]/g, '');
    e.target.value = cleanedInput;
}

function onKeyDownWrapper(e, onKeyDownFunction, type, id) {

    if (onKeyDownFunction) {
        onKeyDownFunction(e)
    }
}


