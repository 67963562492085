import React from 'react'
import './toggle.css';

export default function Toggle(props) {
    return (
        <>
            <div className="toggleContainer" onClick={props.onClick}>
                <div className="toggle">
                    {props.toggle.isActive 
                        ? props.toggle.openToggleHandle
                        : props.toggle.closedToggleHandle}
                </div>
            </div>
            {props.toggle.isActive ? props.toggle.toggleableContent : null}
        </>
    )
}