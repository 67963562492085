import React, { Component } from 'react'
import * as SvgIcon from "Components/SvgIcon/SvgIcon"
import styles from './label.css'

function Label(props) {
    let labelClasses = "label " + props.label.class

    return (
        <div className={labelClasses}>
            <div className={"labelIcon"}>{props.label.icon}</div>
            <div className={"labelText"}>{props.label.text}</div>
        </div>
    )
}


function BannerLabel(props) {
    return (
        <div className={"bannerLabel"}>
            <Label label={props.bannerLabel} > </Label >
        </div>
    )
}


export function LabelShipsToday(props) {
    return (
        <BannerLabel bannerLabel={{ icon: <SvgIcon.ShipsTodaySvgIcon />, text: "Ships Today", class: "shippingCountdown" }} />
    )
}


export function LabelFastShipping(props) {
    return (
        <BannerLabel bannerLabel={{ icon: <SvgIcon.FastShippingSvgIcon />, text: "Fast Shipping", class: "fastShipping" }} />
    )
}


export function LabelSaleCallout(props) {
    return (
        <BannerLabel bannerLabel={{ text: "Sale", class: "saleCallout" }} />
    )
}

export function LabelTechRecommendation(props) {
    return (
        <BannerLabel bannerLabel={{ text: "Tech Pick", class: "techPick" }} />
    )
}


export function LabelFreeShipping(props) {
    return (
        <BannerLabel bannerLabel={{ icon: <SvgIcon.FreeShippingSvgIcon />, text: "Free Shipping", class: "freeShipping" }} />
    )
}


export function LabelDealerAddress(props) {
    return (
        <BannerLabel bannerLabel={{ icon: <SvgIcon.LocationPinSvgIcon />, text: "Dealer Address", class: "dealerAddress" }} />
    )
}

export function LabelPickUpTime(props) {
    return (
        <BannerLabel bannerLabel={{ icon: <SvgIcon.ShipsTodaySvgIcon />, text: "Pickup Time", class: "pickupTime" }} />
    )
}

export function LabelGuaranteed(props) {
    return (
        <Label label={{ icon: <SvgIcon.StarSvgIcon />, text: "Guaranteed", class: "shippingOptionIcon" }} />
    )
}


export function LabelStandardDelivery(props) {
    return (
        <Label label={{ icon: <SvgIcon.StandardDeliverySvgIcon />, text: "Standard Delivery", class: "shippingOptionIcon" }} />
    )
}

export function LabelEmailDelivery(props) {
    return (
        <Label label={{ icon: <SvgIcon.EmailDeliverySvgIcon />, text: props.text ? props.text : "Email Delivery", class: "shippingOptionIcon" }} />
    )
}


export function LabelEconomyDelivery(props) {
    return (
        <Label label={{ icon: <SvgIcon.StandardDeliverySvgIcon />, text: "Economy Delivery", class: "shippingOptionIcon" }} />
    )
}

export function LabelPremiumDelivery(props) {
    return (
        <Label label={{ icon: <SvgIcon.StandardDeliverySvgIcon />, text: "Premium Delivery", class: "shippingOptionIcon" }} />
    )
}

export function LabelOneDayDelivery(props) {
    return (
        <Label label={{ icon: <SvgIcon.StandardDeliverySvgIcon />, text: "1-Day Delivery", class: "shippingOptionIcon" }} />
    )
}


export function LabelTwoDayDelivery(props) {
    return (
        <Label label={{ icon: <SvgIcon.StandardDeliverySvgIcon />, text: "2-Day Delivery", class: "shippingOptionIcon" }} />
    )
}


export function LabelStorePickup(props) {
    return (
        <Label label={{ icon: <SvgIcon.StorePickupSvgIcon />, text: "Store Pickup", class: "shippingOptionIcon" }} />
    )
}

export function LabelFactoryShipped(props) {
    return (
        <Label label={{ icon: <SvgIcon.FactoryShippedSvgIcon />, text: "Factory Shipped", class: "shippingOptionIcon" }} />
    )
}


export function LabelTruckFreightCharge(props) {
    return (
        <Label label={{ icon: <SvgIcon.TruckFreightChargeSvgIcon />, text: "Truck Shipment", class: "shippingOptionIcon" }} />
    )
}


export function LabelMadeToOrder(props) {
    return (
        <Label label={{ icon: <SvgIcon.MadeToOrderSvgIcon />, text: "Made To Order", class: "shippingOptionIcon" }} />
    )
}


export function LabelBackordered(props) {
    return (
        <Label label={{ icon: <SvgIcon.BackOrderedSvgIcon />, text: "Backordered", class: "shippingOptionIcon" }} />
    )
}


export function LabelInternationalShipping(props) {
    return (
        <Label label={{ icon: <SvgIcon.InternationalSvgIcon />, text: props.shippingMethodName, class: "shippingOptionIcon" }} />
    )
}

export function LabelOversizedItem(props) {
    return (
        <Label label={{ icon: <SvgIcon.OversizedItemSvgIcon />, text: "Oversized Item", class: "oversizedItemIcon" }} />
    )
}

export function LabelHazardous(props) {
    return (
        <Label label={{ icon: <SvgIcon.WarningSvgIcon />, text: "Hazardous Item", class: "hazardousIcon" }} />
    )
}

export function LabelKit(props) {
    return (
        <Label label={{ icon: <SvgIcon.KitSvgIcon />, text: "Kit", class: "kitIcon" }} />
    )
}

export function LabelOversizedItemBox(props) {
    return (
        <Label label={{ icon: <SvgIcon.OversizedItemSvgIcon />, text: "Oversized Item", class: "shippingOptionIcon" }} />
    )
}

