import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import styles from './button.css';


function DeprecatedButton(props) {
    return (
        <button type={`${props.button.type ? props.button.type : 'button'}`} id={props.id} className={`button ${props.className ? props.className : ''}`} disabled={props.button.disabled} onClick={props.onClick} aria-label={props.button.ariaLabel ? props.button.ariaLabel : props.button.text}>
            {props.button.text}
        </button>
    )
}


export function ButtonMainCTA(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`primary ${props.className ? props.className : ''}`} />
    )
}


export function ButtonAlternativeCTA(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`alternative ${props.className ? props.className : ''}`} />
    )
}


export function ButtonSecondaryCTA(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`secondary ${props.className ? props.className : ''}`} />
    )
}


export function ButtonTertiaryCTA(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`tertiary ${props.className ? props.className : ''}`} />
    )
}


export function ButtonConfirmation(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`confirmation ${props.className ? props.className : ''}`} />
    )
}

export function ButtonClose(props) {
    return (
        <DeprecatedButton
            button={{ text: "X", disabled: props.button.disabled }}
            onClick={props.onClick}
            className={`close ${props.className ? props.className : ''}`} />
    )
}


export function ButtonPaypalCheckout(props) {
    return (
        <DeprecatedButton
            id={props.id}
            button={props.button}
            onClick={props.onClick}
            className={`paypalCheckout ${props.className ? props.className : ''}`} />
    )
}


export function ButtonPaypalCredit(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`paypalCredit ${props.className ? props.className : ''}`} />
    )
}


export function ButtonApplePay(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`paypalCapplePayredit ${props.className ? props.className : ''}`} />
    )
}


export function ButtonVenmo(props) {
    return (
        <DeprecatedButton
            button={props.button}
            onClick={props.onClick}
            className={`venmo ${props.className ? props.className : ''}`} />
    )
}
