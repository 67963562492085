import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import "./carousel.css"

const propTypes = {
    className: PropTypes.string
};

const defaultProps = {
};

const CarouselItem = (
    {
        className,
        active,
        ...props
    }
) => {
    const prefix = 'carousel-item';

    const classes = classNames(
        prefix,
        active && (prefix + '-active'),
        className,
    );
    return (
        <div className={classes} {...props}>
            <div className={'carousel-item-inner'}>
                {props.children}
            </div>
        </div>
    );
}


CarouselItem.displayName = 'CarouselItem';
CarouselItem.propTypes = propTypes;
CarouselItem.defaultProps = defaultProps;

export default CarouselItem;