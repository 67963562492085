import classNames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FormContext from './FormContext';
import "./FormSelect.css"



const Options = (props) => {
    let optionArray = props.options ? props.options.map((o) => <option key={o} value={o}>{o}</option>) : "";
    return (
        <>
            {optionArray}
        </>
    );
}

const propTypes = {
    /**
     * @default {'form-select'}
     */
    prefix: PropTypes.string,

    /**
     * The FormSelect `ref` will be forwarded to the underlying input element,
     * which means unless it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,


    /**
     * The options of the select
     *
     * @controllable onChange
     * */
    options: PropTypes.array,

    /** A callback fired when the `value` prop changes */
    onChange: PropTypes.func,

    /**
     * The default selected option of the select element
     *
     * @controllable onChange
     * */
    value: PropTypes.string,

    /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
    id: PropTypes.string,

    /** Add "valid" validation styles to the control */
    isValid: PropTypes.bool,

    /** Add "invalid" validation styles to the control and accompanying label */
    isInvalid: PropTypes.bool,
};

const FormSelect = React.forwardRef(
    (
        {
            prefix,
            id,
            className,
            options,
            value,
            isValid,
            isInvalid,
            ...props
        },
        ref,
    ) => {
        const { controlId } = useContext(FormContext);

        prefix = 'form-select';

        const classes = classNames(
            prefix,
            className,
            isValid && `is-valid`,
            isInvalid && `is-invalid`,
        )

        const Component = 'select';

        return (
            <Component
                {...props}
                ref={ref}
                id={id || controlId}
                className={classes} 
                value={value} >
                <Options options={options} />
            </Component>
        );
    },
);

FormSelect.displayName = 'FormSelect';
FormSelect.propTypes = propTypes;

export default FormSelect;
