import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import CarouselItem from './CarouselItem'
import { LeftArrowSvgIcon, RightArrowSvgIcon } from './../SvgIcon/SvgIcon'
import './carousel.css'

const propTypes = {
    className: PropTypes.string,
    step: PropTypes.number
}

const defaultProps = {
    step: 1
}

const Carousel = ({
    className,
    interval,
    wrap,
    pause,
    step,
    leftArrowOnClick,
    rightArrowOnClick,
    ...props
}) => {
    const prefix = 'carousel'

    const classes = classNames(prefix, className, props.children && !props.children[0] && 'hide')

    return (
        <div className={classes} {...props}>
            <button
                className={'carousel-control carousel-control-left'}
                onClick={e => {
                    slide(e, step, 'left')
                    leftArrowOnClick()
                }}
                aria-label="Carousel Left Button"
                tabIndex="-1"
            >
                <LeftArrowSvgIcon />
            </button>
            <div className={'carousel-window'}>
                <div className={'carousel-inner'}>{props.children}</div>
            </div>
            <button
                className={'carousel-control carousel-control-right'}
                onClick={e => {
                     slide(e, step, 'right')
                     rightArrowOnClick()
                }}
                aria-label="Carousel Right Button"
                tabIndex="-1"
            >
                <RightArrowSvgIcon />
            </button>
        </div>
    )
}

Carousel.displayName = 'Carousel'

Carousel.Item = CarouselItem

Carousel.propTypes = propTypes
Carousel.defaultProps = defaultProps

export default Carousel

function slide(e, step, direction) {
    const defaultMargin = 10
    const carousel = e.target.parentElement
    const carouselWindow = carousel.getElementsByClassName('carousel-window')[0]
    const items = carousel.getElementsByClassName('carousel-item')
    const visibleItems = getVisibleItems(items, carouselWindow)

    const trueStep = Math.min(step, visibleItems.length)
    let slideLength = 0

    if (direction === 'right') {
        for (let i = 0; i < trueStep; i++) {
            const item = visibleItems[i]
            const itemWidth = item.getWidth()
            slideLength = slideLength + itemWidth + defaultMargin
        }
    } else if (direction === 'left') {
        for (let i = trueStep - 1; i >= 0; i--) {
            const item = visibleItems[i]
            const itemWidth = item.getWidth()
            slideLength = slideLength + itemWidth + defaultMargin
        }
    }
    scrollCarousel(direction, slideLength, 250, carouselWindow)
}

function scrollCarousel(direction, distance, duration, carouselWindow) {
    const directionNumber = direction === 'right' ? 1 : -1
    const intervalCount = 20
    const avgScrollDistance = distance / intervalCount
    const scrollTime = duration / intervalCount
    let scrollCount = 0;
    let totalScrollDistance = 0
    const scrollStep = Math.PI / (duration / intervalCount)

    const scrollInterval = setInterval(function () {
        if (scrollCount < intervalCount) {
            scrollCount += 1
            carouselWindow.scrollLeft += avgScrollDistance * directionNumber 
            totalScrollDistance += avgScrollDistance
        } else {
            console.log("dist traveled: " + totalScrollDistance + ", commanded: " + distance)
            clearInterval(scrollInterval)

        }
    }, scrollTime)
    
}


function getVisibleItems(items, window) {
    let visibleItems = []
    const windowBounds = window.getBoundingClientRect()

    for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const itemBounds = item.getBoundingClientRect()
        if (itemBounds.right > windowBounds.left && itemBounds.left < windowBounds.right) {
            visibleItems.push(item)
        }
    }

    return visibleItems
}
