import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import styles from './Loader.css'


class Loader extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Fragment>
                {this.props.isLoading ? <div className="loadingIcon"> </div> : ""}
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        isLoading: state.loader.isLoading
    }
}
export default withRouter(connect(mapStateToProps)(Loader))