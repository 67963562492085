import classNames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import "./Checkbox.css"


const propTypes = {
    /**
     * @default {'checkbox'}
     */
    prefix: PropTypes.string,

    /**
     * The Checkbox `ref` will be forwarded to the underlying input element,
     * which means unless it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,

    /** A callback fired when the `value` prop changes */
    onChange: PropTypes.func,

    /**
     * The default selected option of the select element
     *
     * @controllable onChange
     * */
    value: PropTypes.string,

    /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
    id: PropTypes.string,

    /** Add "valid" validation styles to the control */
    isValid: PropTypes.bool,

    /** Add "invalid" validation styles to the control and accompanying label */
    isInvalid: PropTypes.bool,

    /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
    id: PropTypes.string,
};

const Checkbox = React.forwardRef(
    (
        {
            prefix,
            id,
            className,
            value,
            isValid,
            isInvalid,
            ...props
        },
        ref,
    ) => {
        prefix = 'checkbox';

        const classes = classNames(
            prefix,
            className,
            isValid && `is-valid`,
            isInvalid && `is-invalid`
        )

        const Component = 'input';

        return (
            <>
                <div className={"checkboxContainer-alpha"}>
                    <Component
                        {...props}
                        ref={ref}
                        id={id}
                        className={classes}
                        value={value}
                        type={'checkbox'}/>
                    <Checkmark/>
                </div>
            </>
        );
    },
);

const Checkmark = () => {
    return (
        <div className="checkmarkContainer-alpha">
            <svg className={"checkmark-alpha"} viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
            </svg>
        </div>
    )
}

Checkbox.displayName = 'Checkbox';
Checkbox.propTypes = propTypes;

export default Checkbox;
