import React, { Component } from 'react'
import classNames from 'classnames';
import styles from './svgIcon.css';

class SvgIcon extends Component {

    componentDidMount() {
        const script = document.createElement("script");

        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number
        {
            script.src = '/Images/svgxuse.min.js';
            script.async = true;
        }

        document.body.appendChild(script);
    }

    componentDidUpdate() {
        const script = document.createElement("script");

        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number
        {
            script.src = '/Images/svgxuse.min.js';
            script.async = true;
        }

        document.body.appendChild(script);
    }

    render() {

        const classes = classNames(
            this.props.className
        );

        return (
            <svg className={classes}>
                <use xlinkHref={`/Images/checkoutSprite-nov-13-2024.svg#${this.props.iconName}`}></use>
            </svg>
        )
    }
}

export class FactoryShippedSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-factoryShipped",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-factoryShipped" className={classes} />)
    }
}

export class RewardsSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-rewards"
        );
        return (<SvgIcon iconName="icon-rewards" className={classes} />)
    }
}
export class ShipsTodaySvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-shipsToday",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-shipsToday" className={classes} />)
    }
}
export class SaleCalloutSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-freeShipping-inverse",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-freeShipping" className={classes} />)
    }
}
export class BackOrderedSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-backOrdered",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-backordered" className={classes} />)
    }
}
export class BestValueShippingSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-bestValueShipping",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-bestValueShipping" className= {classes} />)
    }
}
export class CloseSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-close",
        );
        return (<SvgIcon iconName="icon-close" className={classes} />)
    }
}
export class DownArrowSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-downArrow",
        );
        return (<SvgIcon iconName="icon-downArrow" className={classes} />)
    }
}
export class RightArrowSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-rightArrow",
        );
        return (<SvgIcon iconName="icon-rightArrow" className={classes} />)
    }
}
export class LeftArrowSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-leftArrow",
        );
        return (<SvgIcon iconName="icon-leftArrow" className={classes} />)
    }
}
export class FastShippingSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-fastShipping",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-fastShipping" className={classes} />)
    }
}
export class FreeShippingSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-freeShipping",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-freeShipping" className={classes} />)
    }
}
export class LocationPinSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-locationPin",
        );
        return (<SvgIcon iconName="icon-locationPin" className={classes} />)
    }
}
export class MadeToOrderSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-madeToOrder",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-madeToOrder" className={classes} />)
    }
}
export class MoreInformationSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-moreInformation",
        );
        return (<SvgIcon iconName="icon-moreInformation" className={classes} />)
    }
}
export class PhoneSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-phone",
        );
        return (<SvgIcon iconName="icon-phone" className={classes} />)
    }
}
export class PlusSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-plus",
        );
        return (<SvgIcon iconName="icon-plus" className={classes} />)
    }
}
export class PrintSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-print",
        );
        return (<SvgIcon iconName="icon-print" className={classes} />)
    }
}
export class SavedCheckmarkSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-savedCheckmark",
        );
        return (<SvgIcon iconName="icon-savedCheckmark" className={classes} />)
    }
}
export class SearchSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-search",
        );
        return (<SvgIcon iconName="icon-search" className={classes} />)
    }
}
export class StandardDeliverySvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-standardDelivery",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-standardDelivery" className={classes} />)
    }
}
export class StarSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-star",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-star" className={classes} />)
    }
}
export class StorePickupSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-storePickup",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-storePickup" className={classes} />)
    }
}
export class TruckFreightChargeSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-truckFreightCharge",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-truckFreightCharge" className={classes} />)
    }
}
export class VerifiedAddressSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-verifiedAddress",
        );
        return (<SvgIcon iconName="icon-verifiedAddress" className={classes} />)
    }
}
export class CartSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-cart",
        );
        return (<SvgIcon iconName="icon-cart" className={classes} />)
    }
}
export class McAfeeSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-mcAfee",
        );
        return (<SvgIcon iconName="icon-mcAfee" className={classes} />)
    }
}
export class BBBSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-BBB",
        );
        return (<SvgIcon iconName="icon-BBB" className={classes} />)
    }
}
export class GoogleTrustedStoreSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-googleTrustedStore",
        );
        return (<SvgIcon iconName="icon-googleTrustedStore" className={classes} />)
    }
}
export class WarningSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-warning",
        );
        return (<SvgIcon iconName="icon-warning" className={classes} />)
    }
}
export class LockSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-lock",
        );
        return (<SvgIcon iconName="icon-lock" className={classes} />)
    }
}
export class InternationalSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-international",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-international" className={classes} />)
    }
}
export class BlockedSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-blocked"
        );
        return (<SvgIcon iconName="icon-blocked" className={classes} />)
    }
}
export class Prop65SvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-prop65"
        );
        return (<SvgIcon iconName="icon-prop65" className={classes} />)
    }
}
export class SmiWheelSvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-smiWheel"
        );
        return (<SvgIcon iconName="icon-smiWheel" className={classes} />)
	}
}
export class OversizedItemSvgIcon extends Component {
	render() {
		const classes = classNames(
			this.props.className,
			"iconSVG",
			"icon-oversizedItem"
		);
        return (<SvgIcon iconName="icon-oversizedItem" className={classes} />)
	}
}
export class UniversalFitSvgIcon extends Component {
	render() {
		const classes = classNames(
			this.props.className,
			"iconSVG",
			"icon-universalFit"
		);
        return (<SvgIcon iconName="icon-universalFit" className={classes} />)
	}
}
export class KitSvgIcon extends Component {
	render() {
		const classes = classNames(
			this.props.className,
			"iconSVG",
			"icon-kit"
		);
		return (<SvgIcon iconName="icon-kit" className={classes} />)
	}
}
export class EmailDeliverySvgIcon extends Component {
    render() {
        const classes = classNames(
            this.props.className,
            "iconSVG",
            "icon-EmailDelivery",
            "shippingBadgeSVG"
        );
        return (<SvgIcon iconName="icon-EmailDelivery" className={classes} />)
    }
}
