import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import { IMAGE_NOT_FOUND_URL } from 'Utilities/Constants';


const propTypes = {
    /**
    * If the Image has a webp format in S3
    */
    hasWebp: PropTypes.bool.isRequired,

    /**
    * The url of the image, either S3 or SOP's host
    */
    url: PropTypes.string.isRequired,

    /**
    * (Optional) Clicking on the picture navigates the user to this url
    */
    href: PropTypes.string,

    /**
    * (Optional) Clicking on the picture runs this function
    */
    onClick: PropTypes.func,

    /**
    * (Optional) Used for image accessibility, also serves as the alt text
    */
    title: PropTypes.string,

    /**
    * (Optional) Used for when an image is not jpg
    */
    fallbackExtension: PropTypes.string,

    /**
    * (Optional) will only be used if href or onClick is used
    */
    tabIndex: PropTypes.number
};

const defaultProps = {
    hasWebp: false,
    fallbackExtension: 'jpg'
};

const MediaImage = (
    {
        className,
        hasWebp,
        url,
        target,
        href,
        onClick,
        title,
        fallbackExtension,
        tabIndex,
        ...props
    }
) => {
    const prefix = 'media-image';

    const classes = classNames(
        prefix,
        className
    );

    url = url ? url : IMAGE_NOT_FOUND_URL;
    hasWebp = url === IMAGE_NOT_FOUND_URL ? false : hasWebp;

    let webpSource = false;
    if (hasWebp) {

        if (fallbackExtension !== 'jpg') {
            const regexOutput = url.replace(new RegExp('.' + fallbackExtension, 'gi'), '.webp');
            webpSource = (
                <source
                    srcSet={regexOutput}
                    type="image/webp"
                />
            );
        } else {
            webpSource = (
                <source
                    srcSet={url.replace(/.jpg/gi, '.webp').replace(/.jpeg/gi, '.webp')}
                    type="image/webp"
                />
            );
        }
    }

    let picture = (
        <picture>
            {webpSource}
            <source srcSet={url} type={`image/${fallbackExtension}`} />
            <img className={classes} alt={title} title={title} src={url} />
        </picture>
    );

    if (href || onClick) {
        const safeHref = href ? href : "javascript:void(0);";
        const safeOnClick = onClick ? onClick : () => { };
        const safeTarget = target ? target : "_self";

        if (safeTarget === "_blank" && tabIndex) {
            picture = (
                <a href={safeHref} onClick={safeOnClick} tabIndex={tabIndex} target={safeTarget} rel={"noopener"}>
                    {picture}
                </a>
            );
        } else if (safeTarget === "_blank") {
            picture = (
                <a href={safeHref} onClick={safeOnClick} target={safeTarget} rel={"noopener"}>
                    {picture}
                </a>
            );
        } else if (tabIndex) {
            picture = (
                <a href={safeHref} onClick={safeOnClick} tabIndex={tabIndex} target={safeTarget}>
                    {picture}
                </a>
            );
        } else {
            picture = (
                <a href={safeHref} onClick={safeOnClick} target={safeTarget}>
                    {picture}
                </a>
            );
        }
    }


    return (
        <>
            {picture}
        </>
    );
};

MediaImage.displayName = 'MediaImage';
MediaImage.propTypes = propTypes;
MediaImage.defaultProps = defaultProps;

export default MediaImage;