import classNames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import "./FormTextbox.css"

import FormContext from './FormContext';

const propTypes = {
    /**
     * The FormControl `ref` will be forwarded to the underlying input element,
     * which means unless `as` is a composite component,
     * it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,

    /**
     * The `value` attribute of underlying input
     *
     * @controllable onChange
     * */
    value: PropTypes.string,

    /** A callback fired when the `value` prop changes */
    onChange: PropTypes.func,

    /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
    id: PropTypes.string,

    /**
     * The HTML input `type`
     *
     * @type {('email'|'tel'|'password'|'text'|'number')}
     *
     */
    type: PropTypes.string,

    /**
     * Only used when type='number'
     *
     */
    min: PropTypes.string,

    /**
     * Only used when type='number'
     *
     */
    max: PropTypes.string,

    /** Add "valid" validation styles to the control */
    isValid: PropTypes.bool,

    /** Add "invalid" validation styles to the control and accompanying label */
    isInvalid: PropTypes.bool,

    /** Hides the spinners (up/down arrows) for number textboxes */
    hideSpinners: PropTypes.bool
};

const defaultProps = {
    type: "text"
};

const FormTextbox = React.forwardRef(
    (
        {
            id,
            type,
            min,
            max,
            className,
            value,
            onChange,
            isValid,
            isInvalid,
            errors,
            hideSpinners,
            ...props
        },
        ref,
    ) => {
        const { controlId } = useContext(FormContext);

        const prefix = 'form-textbox';

        if (errors && errors.length && !isValid) {
            isInvalid = true
        }

        const classes = classNames(
            prefix,
            className,
            isValid && 'is-valid',
            isInvalid && 'is-invalid',
            type === 'number' && hideSpinners && `${prefix}-hideSpinners`
        )

        const Component = 'input';

        return (
            <>
                <Component
                    {...props}
                    onChange={(e) => onChangeWrapper(onChange, e)}
                    type={type}
                    ref={ref}
                    id={id || controlId}
                    className={classes}
                    value={value}
                    min={type === 'number' && min || null}
                    max={type === 'number' && max || null}
                    maxLength={type === 'number' && max.length || null}
                    onKeyDown={(e) => onKeyDown(type, e)}
                    onPaste={(e) => onPasteWrapper(onChange, e)}
                />
                {errors ? errors.map((message, index) => <div key={index} className="errorMessage">{message}</div>) : false}
            </>
        );
    },
);

FormTextbox.displayName = 'FormTextbox';
FormTextbox.propTypes = propTypes;
FormTextbox.defaultProps = defaultProps;


export default FormTextbox;

function onKeyDown(type, e) {
    if (type === 'number') {
        e = e || window.event;
        var key = (typeof e.which == "number") ? e.which : e.keyCode;
        var ctrl = e.ctrlKey || e.metaKey;

        // when a keypress event occurs on the 0-9 keys the value
        // of the charCode is between 48 - 57 and 96 - 105 for the keypad

        if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
            // 0-9 only
        } else if (key === 8 || key === 9 || key === 37 || key === 39) {
            // left arrow (37), right arrow (39), delete (8) or tab (9)
        } else if (key === 13) {
            //enter key(13)
        } else if (ctrl) {
            //ctrl + v(86), c(67), x(88), z(90), a(65), y(89), and many more
        } else {
            e.preventDefault();
        }
    }
}


function onChangeWrapper(onChange, e) {
    e.preventDefault();

    restrictNumberFieldNonNumbers(e);
    restrictNumberFieldMaxLength(e);


    if (onChange) {
        onChange(e);
    }
}

function restrictNumberFieldNonNumbers(e) {
    if (e.target.type !== 'number')
        return;

    if (!e.target.value)
        return;

    e.target.value = ('' + e.target.value).replace(/\D/g, '');
}

function restrictNumberFieldMaxLength(e) {
    if (e.target.type !== 'number')
        return;

    if (!e.target.maxLength)
        return;

    if (!e.target.value)
        return;

    if (e.target.value.length > e.target.maxLength)
        e.target.value = e.target.value.slice(0, e.target.maxLength);
}



function onPasteWrapper(onChange, e) {
    if (e.target.type === 'number') {
        e.preventDefault();

        const unformattedClipboard = e.clipboardData.getData('text');
        const formattedClipboard = ('' + unformattedClipboard).replace(/\D/g, '');

        const currentTargetValue = e.target.value || '';

        let newValue = '' + currentTargetValue + formattedClipboard;

        if (e.target.maxLength && newValue.length > e.target.maxLength) {
            newValue = newValue.slice(0, e.target.maxLength);
        }

        e.target.value = newValue;

        if (onChange) {
            onChange(e)
        }
    }
}