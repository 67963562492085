import React, { useRef, useState, useEffect, Fragment } from 'react'
import ClassNames from 'classnames'
import { usePopper } from 'react-popper'
import useOutsideClick from 'Utilities/useOutsideClick'
import useKeyPress, { KEYCODES } from 'Utilities/Keypress'
import FadeInFadeOut from 'Utilities/FadeInFadeOut/FadeInFadeOut'
import popoverStyles from './Popover.css'

const Popover = ({
    children,
    content,
    className,
    isOpen,
    onToggleMenu,
    setCustomParentTriggerRef,
    staticComponentWidth
}) => {
    const wrapperRef = useRef(null)
    const popperRef = useRef(null)
    // the ref for the arrow must be a callback ref
    const [arrowRef, setArrowRef] = useState(null) 

    let offset = 0;
    let isIE = null;

    if (typeof window !== 'undefined' && staticComponentWidth) {
        offset = (window.outerWidth - staticComponentWidth) / 2
    }

    if (typeof window !== 'undefined') {
        isIE = /*@cc_on!@*/false || !!document.documentMode;
    }


    const { styles: popperStyles, attributes: popperAttributes } = usePopper(
        wrapperRef.current,
        popperRef.current,
        {
            modifiers: [
                {
                    name: 'arrow',
                    options: {
                        element: arrowRef
                    }
                },
                {
                    name: 'offset',
                    options: {
                        offset: [offset, 0]
                    }
                }
            ]
        }
    )

    const { current } = wrapperRef
    useEffect(() => {
        if (current && setCustomParentTriggerRef) {
            setCustomParentTriggerRef(current)
        }
    }, [current, setCustomParentTriggerRef])

    useOutsideClick(popperRef, [wrapperRef], () => onToggleMenu(false))
    useKeyPress(KEYCODES.escape, () => onToggleMenu(false))

    return (
        <Fragment>
            <div
                ref={wrapperRef}
                className={ClassNames(className, 'popoverTrigger')}
                onClick={() => {
                    onToggleMenu(!isOpen)
                }}
            >
                {children}
            </div>
            {!isIE ? (
                <FadeInFadeOut
                    isOpen={isOpen}
                >
                <div
                        className={'popover'}
                    ref={popperRef}
                    style={popperStyles.popper}
                    {...popperAttributes.popper}
                >
                    <div
                        ref={setArrowRef}
                        style={popperStyles.arrow}
                        className={'popoverArrow'}
                        id="arrow"
                    />
                    <div className={'wrapper'}>{content}</div>
                </div>
                </FadeInFadeOut>
            ) :
                <Fragment>
                    {(isOpen) ? (
                        <div
                            className={'popover'}
                            ref={popperRef}
                            style={popperStyles.popper}
                            {...popperAttributes.popper}
                        >
                            <div
                                ref={setArrowRef}
                                style={popperStyles.arrow}
                                className={'popoverArrow'}
                                id="arrow"
                            />
                            <div className={'wrapper'}>{content}</div>
                        </div>
                    ) : false}
                </Fragment>
            }
        </Fragment>
    )
}
export default Popover
