import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import FormSelect from './FormSelect';
import FormTextbox from './FormTextbox';
import FormContext from './FormContext';

import Button from './../Button/Button';
import Row from './../Row/Row';

const propTypes = {
    /**
     * @default {'form-select-textbox'}
     */
    prefix: PropTypes.string,

    /**
     * The FormSelect `ref` will be forwarded to the underlying input element,
     * which means unless it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,


    /**
     * The normal options that do not cause the select to change into a textbox
     *
     * @controllable onChange
     * */
    options: PropTypes.array,

    /**
     * The select option that causes the select to change into a textbox
     *
     * @controllable onChange
     * */
    textboxTriggerOption: PropTypes.string,

    /**
     * The default value when the select swaps to an textbox
     *
     * */
    defaultTextboxValue: PropTypes.string,

    /**
     * The default value when the FormSelectWithTextbox is instantiated
     *
     * */
    defaultValue: PropTypes.string,

    /**
     * The variant of the submit button. Only visible when the textbox is displayed
     *
     * */
    buttonVariant: PropTypes.string,

    /**
     * The text of the submit button. Only visible when the textbox is displayed
     *
     * */
    buttonText: PropTypes.string,

    /**
     * The HTML input `type`
     *
     * @type {('email'|'tel'|'password'|'text'|'number')}
     *
     */
    type: PropTypes.string,

    /**
     * Only used when type='number'
     *
     */
    min: PropTypes.string,

    /**
     * Only used when type='number'
     *
     */
    max: PropTypes.string,

    /** A callback fired when the `value` prop changes */
    onChange: PropTypes.func,

    /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
    id: PropTypes.string,
};

const FormSelectWithTextbox = React.forwardRef(
    (
        {
            prefix,
            id,
            className,
            options,
            textboxTriggerOption,
            defaultTextboxValue,
            defaultValue,
            buttonVariant,
            buttonText,
            type,
            min,
            max,
            onChange,
            ...props
        },
        ref,
    ) => {
        const { controlId } = useContext(FormContext);

        let optionsIncludeDefaultValue = false
        if (defaultValue) {
            optionsIncludeDefaultValue = options.includes(defaultValue)
        }
        const [buttonHidden, updateButtonHidden] = useState(false)
        const [textboxOptions, updateTextboxOptions] = useState({
            isTextboxDisplayed: !optionsIncludeDefaultValue,
            selectedOption: optionsIncludeDefaultValue ? defaultValue : null,
            textboxValue: optionsIncludeDefaultValue ? null : defaultValue,
        })


        prefix = 'form-select-textbox';

        const classes = classNames(
            prefix,
            className
        )

        id = id || controlId;

        let textBoxChangeEvent = function (e) {
            e.preventDefault();
            updateTextboxOptions({ ...textboxOptions, textboxValue: e.target.value })
            updateButtonHidden(false)
            //if (onChange) {
            //    onChange();
            //}
        }

        const minimum = type === 'number' && min || null;
        const maximum = type === 'number' && max || null;

        buttonText = buttonText || 'Submit';
        buttonVariant = buttonVariant || 'secondary';
        let button = false                   
        if(buttonText == "Update") {
            if (!buttonHidden) {
                button = <Button variant={buttonVariant} type="submit" value={buttonText} onClick={() => { ButtonClickEvent(onChange, id, options, updateTextboxOptions); updateButtonHidden(true) }} />
            }

        }
        else {
            button = <Button variant={buttonVariant} type="submit" value={buttonText} onClick={() => ButtonClickEvent(onChange, id, options, updateTextboxOptions)} />

        }
        
        if (textboxOptions.isTextboxDisplayed) {
            return (
                <Row>
                    <FormTextbox
                        {...props}
                        ref={ref}
                        id={id}
                        className={classes}
                        type={type}
                        onChange={textBoxChangeEvent}
                        onBlur={(e) => ChangeEvent(null, id, textboxTriggerOption, defaultTextboxValue, options, textboxOptions, updateTextboxOptions, minimum, maximum, updateButtonHidden, e)}
                        value={textboxOptions.textboxValue || ""}
                        min={minimum}
                        max={maximum}
                    />
                    {button}
                </Row>
            );
        }

        if (!options.includes(textboxTriggerOption)) {
            options.push(textboxTriggerOption);
        }
        return (
            <FormSelect
                {...props}
                ref={ref}
                id={id}
                className={classes}
                options={options}
                onChange={(e) => ChangeEvent(onChange, id, textboxTriggerOption, defaultTextboxValue, options, textboxOptions, updateTextboxOptions, minimum, maximum, updateButtonHidden, e)}
                value={defaultValue || options[0]}
            />
        );
    },
);

FormSelectWithTextbox.displayName = 'FormSelectWithTextbox';
FormSelectWithTextbox.propTypes = propTypes;

export default FormSelectWithTextbox;


function ButtonClickEvent(onChange, id, options, updateTextboxOptions) {
    const element = document.getElementById(id);
    let value;

    if (element) {
        value = element.value;
    }

    if (value) {
        if (options.includes(value)) {
            updateTextboxOptions({
                isTextboxDisplayed: false,
                selectedOption: value,
                textboxValue: null
            })

        }
    }
    if (onChange) {
        onChange();
    }
}

function ChangeEvent(onChange, id, textboxTriggerOption, defaultTextboxValue, options, textboxOptions, updateTextboxOptions, minimum, maximum, updateButtonHidden, e) {
    e.preventDefault();

    const element = document.getElementById(id);
    let value;

    if (textboxOptions.isTextboxDisplayed) {
        if (element) {
            value = element.value;
        }

        if (value) {
            if (minimum && value < minimum) {
                value = minimum;
            } else if (maximum && value > maximum) {
                value = maximum;
            }
            updateTextboxOptions({ ...textboxOptions, textboxValue: value })
        } else {
            updateTextboxOptions({ ...textboxOptions, textboxValue: defaultTextboxValue })
        }
    } else {
        if (element && element.options && element.selectedIndex) {
            value = element.options[element.selectedIndex].value;
        }
        if (value) {
            if (value === textboxTriggerOption) {
                updateButtonHidden(false)
                updateTextboxOptions({
                    isTextboxDisplayed: true,
                    selectedOption: null,
                    textboxValue: defaultTextboxValue
                })
            } else {
                updateTextboxOptions({
                    ...textboxOptions,
                    selectedOption: null,
                    textboxValue: value
                })
            }
        } else {
            updateTextboxOptions({
                ...textboxOptions,
                selectedOption: options[0],
                textboxValue: null
            })
        }
    }

    if (onChange) {
        onChange();
    }
}