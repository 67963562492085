import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import Tooltip from 'Components/Tooltip/Tooltip'

import {sendGoogleAnalyticsEvent} from 'Actions/googleAnalyticsAction';
import './infoIcon.css';
import PropTypes from 'prop-types';

import {Manager, Popper, Reference} from 'react-popper';

const InfoIcon = ({sendGoogleAnalyticsEvent, gaCategory, name, infoIcon}) => {
    const node = useRef();

    const [open, setOpen] = useState(false);

    const handleClickOutside = e => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    };

    // const handleChange = selectedValue => {
    //     onChange(selectedValue);
    //     setOpen(false);
    // };

    useEffect(() => {
        if (open) {
            if(!navigator.globalPrivacyControl && !document.cookie.includes('EnableGpc=')) {
                sendGoogleAnalyticsEvent(gaCategory, 'Clicked ' + name + ' tooltip')
            }
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);
      

    return (
        <>
            <span ref={node} onClick={e => setOpen(!open)} >
            
                <Manager>
                    <Reference>
                        {({ ref }) => (
                            <div className="iconTooltip" ref={ref}>
                                {infoIcon.svg}
                            </div>

                        )}
                    </Reference>
                    {open && (
                        <Popper placement="top">
                        {({ ref, style, placement, arrowProps, scheduleUpdate }) => (
                            <div ref={ref} className="popper" style={style} data-placement={placement}>
                                {open ?
                                    <Tooltip scheduleUpdate={scheduleUpdate}>
                                        {infoIcon.text}
                                    </Tooltip> : false}
                                
                            </div>
                        )}
                        </Popper>
                    
                    )}
                </Manager> 
    </span>
        </>
    )
}

const mapDispatchToProps = {
	sendGoogleAnalyticsEvent
}

InfoIcon.propTypes = {
	gaCategory: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
}

export default connect(null,mapDispatchToProps)(InfoIcon)

