import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import './Typography.css'

const propTypes = {
    /**
     * 
     *
     * @type {('h6'|'h5'|'h4'|'h3'|'h2'|'h1')}
     */
    size: PropTypes.string,

    /**
     * 
     *
     */
    bold: PropTypes.bool,

    /**
     * 
     *
     */
    italic: PropTypes.bool,

    /**
     *
     *
     */
    strikeThrough: PropTypes.bool,

    /**
     *
     *
     */
    uppercase: PropTypes.bool,

    /**
     *
     *
     */
    as: PropTypes.string,

    /**
     *
     *
     */
    className: PropTypes.string,

    /**
    * 
    *
    */
    center: PropTypes.bool,
};

const defaultProps = {
    bold: false,
    italic: false,
    strikeThrough: false,
    center: false,
    uppercase: false
};

const Typography = React.forwardRef(
    (
        {
            size,
            bold,
            italic,
            center,
            strikeThrough,
            uppercase,
            as,
            className,
            ...props
        },
        ref,
    ) => {
        const prefix = 'type';
        const Component = as || 'p';

        if (as && !size) {
            switch (as) {
                case 'h1':
                case 'h2':
                case 'h3':
                case 'h4':
                case 'h5':
                case 'h6':
                {
                    size = as;
                }
            }
        }

        const classes = classNames(
            prefix,
            size && `${prefix}-${size}`,
            bold && `${prefix}-bold`,
            italic && `${prefix}-italic`,
            center && `${prefix}-center`,
            strikeThrough && `${prefix}-strikeThrough`,
            uppercase&& `${prefix}-uppercase`,
            className
        )

        return <Component {...props} className={classes} />
    },
);

Typography.displayName = 'Typography';
Typography.propTypes = propTypes;
Typography.defaultProps = defaultProps;

export default Typography;