import React, { useState } from "react";
import { CloseSvgIcon } from "Components/SvgIcon/SvgIcon";
import Button from "Components/Button/Button";
import "./modal.css";

const Modal = (
    {
        id,
        className,
        header,
        content,
        modalContainerCss,
        showModalExit,
        ...props
    }) => {
    const [open, toggleOpenClose] = useState(true);

    return (
        <div
            id={`${id ? id : ""}`}
            className={`reactModal ${open ? false : "closed"} ${className ? className : ""}`}
            onClick={() => (showModalExit ? toggleOpenClose(!open) : false)}
        >
            <div className="modalContainer" style={modalContainerCss} onClick={(e) => e.stopPropagation()}>
                <div className="modalHeader">
                    {header}
                    {showModalExit ? (
                        <Button
                            className={"modalExit"}
                            variant={"link"}
                            onClick={() => toggleOpenClose(!open)}
                            ariaLabel={"Close Modal."}
                        >
                            <CloseSvgIcon />
                        </Button>
                    ) : (
                        false
                    )}
                </div>
                <div className="modalContent">{content}</div>
            </div>
        </div>
    );
};

export default Modal;
