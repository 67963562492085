import { connect } from "react-redux";


const AntiForgeryToken = (props) => {
    props.fetchAntiForgeryToken()

    return false
}

const fetchAntiForgeryToken = () => (dispatch, getState) => {

    fetch("/antiforgery/getantiforgerytoken",
        {
            method: "GET",
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if (!json.success) {
                console.log("Unable to set antiforgery token")
            }
            dispatch(receiveAntiForgeryToken(json))
        })
        .catch(error => {
            console.error('getantiforgerytoken', error)
            alert('Sorry, an error occurred. Please try again or call customer service at 800.979.0122')
        })
}

//ActionTypes
export const FETCH_ANTI_FORGERY_TOKEN = "FETCH_ANTI_FORGERY_TOKEN";
export const RECEIVE_ANTI_FORGERY_TOKEN = "RECEIVE_ANTI_FORGERY_TOKEN";


//Actions
const requestAntiForgeryToken = () => ({
    type: FETCH_ANTI_FORGERY_TOKEN
});
const receiveAntiForgeryToken = (json) => ({
    type: RECEIVE_ANTI_FORGERY_TOKEN,
    payload: json
})

const initialState = {}

export const antiForgeryTokenReducer = (state, action) => {
    state = state || initialState

    switch (action.type) {
        case RECEIVE_ANTI_FORGERY_TOKEN:
            return {
                ...state,
                ...action.payload
            }
        default: return state
    }
}

const mapDispatchToProps = {
    fetchAntiForgeryToken,
    receiveAntiForgeryToken
};


const mapStateToProps = state => {
    return {
        token: state.token
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AntiForgeryToken);