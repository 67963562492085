import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'


import "./rating.css"

const propTypes = {
    className: PropTypes.string,
    stars: PropTypes.number.isRequired,
};

const defaultProps = {
};

const Rating = (
    {
        className,
        stars,
        ...props
    }
) => {
    const prefix = 'rating';
    const ratingStep = 0.25;

    const classes = classNames(
        prefix,
        className,
    );
    //this round only works for as long as the partial stars only go to quarters
    let starSVGs = fiveStars((Math.round(stars * 4) / 4))
    return (
        <>
            <svg className="invisibleGradient" >
                <linearGradient id="quarter" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="37%" stopColor="rgb(0,169,224)" stopOpacity="1" />
                    <stop offset="37%" stopColor="rgb(196,196,196)" stopOpacity="1" />
                </linearGradient>
                <linearGradient id="half" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="50%" stopColor="rgb(0,169,224)" stopOpacity="1" />
                    <stop offset="50%" stopColor="rgb(196,196,196)" stopOpacity="1" />
                </linearGradient>
                <linearGradient id="threeQuarters" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="63%" stopColor="rgb(0,169,224)" stopOpacity="1" />
                    <stop offset="63%" stopColor="rgb(196,196,196)" stopOpacity="1" />
                </linearGradient>
            </svg>
            <div className="ratingStars">
                {starSVGs}
            </div>

        </>
    );
}

function starSVG(classes, key) {
    return (
        <svg className={classes} key={key} xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024">
            <path d="M511.999 75.094l109.227 341.333h354.987l-286.72 204.8 109.227 327.68-286.72-204.8-286.72 204.8 109.227-327.68-286.72-204.8h354.987l109.227-341.333z"></path>
        </svg>
    )
}
function fiveStars(starCount) {
    let stars = []
    let i
    for (i = 1; i <= 5; i++) {
        if (starCount >= 1) {
            stars.push(starSVG("full", i))
        }
        else if (starCount == 0.75) {
            stars.push(starSVG("threeQuarters", i))
        }
        else if (starCount == 0.5) {
            stars.push(starSVG("half", i))
        }
        else if (starCount == 0.25) {
            stars.push(starSVG("quarter", i))
        }
        else {
            stars.push(starSVG("empty", i))
        }
        starCount--
    }
    return stars
}

Rating.displayName = 'Rating';
Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;

export default Rating;