import classNames from 'classnames';
import React from 'react'
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string
};

const defaultProps = {

};

const CardBody = (
    {
        className,
        ...props
    }
) => {

    const prefix = 'card-body';

    const classes = classNames(
        prefix,
        className
    );

    const Component = 'div';

    return (
        <Component
            className={classes}
            {...props} />
    );
};

CardBody.displayName = 'CardBody';
CardBody.propTypes = propTypes;
CardBody.defaultProps = defaultProps;

export default CardBody;