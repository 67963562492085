import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';


import FormGroup from './FormGroup';
import FormSelect from './FormSelect';
import FormSelectWithTextbox from './FormSelectWithTextbox';
import FormTextbox from './FormTextbox';
import FormTextarea from './FormTextarea';
import FormLabel from './FormLabel';
import AntiForgeryToken from './AntiForgeryToken';

import Button from './../Button/Button';

const propTypes = {
    /**
     * @default {'form'}
     */
    prefix: PropTypes.string,

    /**
     * The Form `ref` will be forwarded to the underlying element,
     * which means, unless it's rendered `as` a composite component,
     * it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,

    /**
     * Display the series of labels, form controls,
     * and buttons on a single horizontal row
     */
    inline: PropTypes.bool,

    /** A function fired when the form is submitted */
    onSubmit: PropTypes.func,
};

const defaultProps = {
    inline: false,
};

const Form = React.forwardRef(
    (
        {
            prefix,
            inline,
            className,
            onSubmit,
            ...props
        },
        ref,
    ) => {
        prefix = 'form';

        let classes = classNames(
            prefix,
            inline && `${prefix}-inline`,
            className
        )

        const Component = 'form';

        return (
            <Component
                {...props}
                ref={ref}
                className={classes}
                onSubmit={(e) => SubmitForm(onSubmit, e)}
            />
        );
    },
);

Form.displayName = 'Form';
Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

Form.Group = FormGroup;
Form.Select = FormSelect;
Form.SelectWithTextbox = FormSelectWithTextbox;
Form.Textbox = FormTextbox;
Form.Textarea = FormTextarea;
Form.Label = FormLabel;
Form.Button = Button;
Form.AntiForgeryToken = AntiForgeryToken;

export default Form;



function SubmitForm(submitEvent, e) {
    e.preventDefault();

    const fields = e.target.elements;

    if (fields && fields.length !== 0) {
        for (let i = 0; i < fields.length; i++) {
            fields[i].focus();
            fields[i].blur();
        }
    }

    if (submitEvent) {
        submitEvent();
    }
}