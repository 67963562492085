import React from 'react';
import { connect } from 'react-redux';
import "./Checkboxes.css"

function Checkbox(props) {
    return (
        props.infoicon ?
            <div className="checkboxIconContainer">
                {checkboxInternal(props)}
                {props.infoicon}               
            </div>
            : checkboxInternal(props)
    )
}

const checkboxInternal = (props) => {
    return (
        <label className="checkboxContainer">
            <input
                role="checkbox"
                type="checkbox"
                text={props.text}
                checked={props.value}
                name={props.name}
                onChange={props.onChange}
            />
            <div className="checkmarkContainer">
                <span className="checkmark">
                    <div className="checkmark_stem"></div>
                    <div className="checkmark_kick"></div>
                </span>
            </div>
            {props.text}
        </label>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        value: state.transaction[ownProps.formName][ownProps.propName]
    }
}

export default connect(mapStateToProps, null)(Checkbox)