import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import FormContext from './FormContext';

const propTypes = {
    /**
     * @default 'form-group'
     */
    prefix: PropTypes.string,


    /**
     * Sets `id` on `<FormControl>` and `htmlFor` on `<FormGroup.Label>`.
     */
    controlId: PropTypes.string,

    /**
     * The FormGroup `ref` will be forwarded to the underlying element.
     * Unless the FormGroup is rendered `as` a composite component,
     * it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,
};

const FormGroup = React.forwardRef(
    (
        {
            prefix,
            className,
            children,
            controlId,
            ...props
        },
        ref,
    ) => {
        prefix = 'form-group';
        const context = useMemo(() => ({ controlId }), [controlId]);

        const classes = classNames(
            prefix,
            className
        );

        const Component = 'div';

        return (
            <FormContext.Provider value={context}>
                <Component
                    {...props}
                    ref={ref}
                    className={classes}
                >
                    {children}
                </Component>
            </FormContext.Provider>
        );
    },
);

FormGroup.displayName = 'FormGroup';
FormGroup.propTypes = propTypes;

export default FormGroup;