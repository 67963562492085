import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import './row.css'

const propTypes = {
};

const defaultProps = {
};

const Row = React.forwardRef((props, ref) => {
    const {
        className,
        ...otherProps
    } = props;

    const prefix = 'row';

    const classes = classNames(
        prefix,
        className
    );

    const Component = 'div';

    return (
        <Component
            ref={ref}
            className={classes}
            {...otherProps}
        />
    );
});

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;