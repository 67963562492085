import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import "./Radio.css"

export class Radio extends Component {
    render() {
        const { name, selectedValue, onChange } = this.context.radioGroup
        const optional = {};
        let radioHeadingClass = "radioHeading" + (this.props.headingClass ? " " + this.props.headingClass : "")

        if (typeof selectedValue !== "undefined") {
            optional.checked = (this.props.value === selectedValue)
        }

        if (typeof onChange === 'function') {
            optional.onChange = onChange.bind(null, this.props.value)
        }

        if (optional.checked) {
            radioHeadingClass += " selected"
        }

        /* onChange note:
         *      This is to get around our current implementation of the payment radio buttons and how they use onChange when they should not
         *      It stops warnings when creating an input without an onChange function.
         */
        return (
            <Fragment>
                <input value={this.props.value} onChange={this.props.onChange ? this.props.onChange : () => { }} role="radio" aria-checked={optional.checked} type="radio" name={name} aria-label={this.props.ariaLabel} tabIndex={optional.checked ? "0" : "-1"} {...optional} />
                <span className="checkmark" />
                {this.props.heading ? <div className={radioHeadingClass}>{this.props.heading}</div> : null}
                <div className="radioContent">{this.props.content}</div>
            </Fragment>
        );
    }
};

Radio.contextTypes = {
    radioGroup: PropTypes.object
};

export class RadioGroup extends React.Component {
    getChildContext() {
        const { name, selectedValue, onChange } = this.props;
        return {
            radioGroup: {
                name, selectedValue, onChange
            }
        }
    }

    render() {
        const { Component, name, selectedValue, onChange, children, ...rest } = this.props;
        return <Component role="radiogroup" className={name} {...rest}>{children}</Component>;
    }
};

RadioGroup.defaultProps = {
    Component: "div"
};

RadioGroup.propTypes = {
    name: PropTypes.string,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    onChange: PropTypes.func,
    children: PropTypes.node.isRequired,
    Component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ])
};

RadioGroup.childContextTypes = {
    radioGroup: PropTypes.object
};