import classNames from 'classnames';
import React from 'react'
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string
};

const defaultProps = {

};

const CardFooter = (
    {
        className,
        ...props
    }) => {

    const prefix = 'card-footer';

    const classes = classNames(
        prefix,
        className
    );

    const Component = 'div';

    return (
        <Component
            className={classes}
            {...props} />
    );
}

CardFooter.displayName = 'CardFooter';
CardFooter.propTypes = propTypes;
CardFooter.defaultProps = defaultProps;

export default CardFooter;