import classNames from 'classnames';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FormContext from './FormContext';

const propTypes = {
    /**
     * @default {'form-textarea'}
     */
    prefix: PropTypes.string,

    /**
     * The FormControl `ref` will be forwarded to the underlying input element,
     * which means unless `as` is a composite component,
     * it will be a DOM node, when resolved.
     *
     * @type {ReactRef}
     * @alias ref
     */
    _ref: PropTypes.any,

    /**
     * The `value` attribute of underlying input
     *
     * @controllable onChange
     * */
    value: PropTypes.string,

    /** A callback fired when the `value` prop changes */
    onChange: PropTypes.func,

    /**
     * Uses `controlId` from `<FormGroup>` if not explicitly specified.
     */
    id: PropTypes.string,

    /** Add "valid" validation styles to the control */
    isValid: PropTypes.bool,

    /** Add "invalid" validation styles to the control and accompanying label */
    isInvalid: PropTypes.bool,
};

const FormTextarea = React.forwardRef(
    (
        {
            prefix,
            id,
            className,
            isValid,
            isInvalid,
            ...props
        },
        ref,
    ) => {
        const { controlId } = useContext(FormContext);

        prefix = 'form-textarea';

        const classes = classNames(
            prefix,
            className,
            isValid && 'is-valid',
            isInvalid && 'is-invalid',
        )

        const Component = 'textarea';

        return (
            <Component
                {...props}
                ref={ref}
                id={id || controlId}
                className={classes}
            />
        );
    },
);

FormTextarea.displayName = 'FormTextarea';
FormTextarea.propTypes = propTypes;


export default FormTextarea;